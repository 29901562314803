<template>
  <LayoutBox title="远程巡检">
    <v-row style="margin-top: 8px">
      <QueryLayout
        :cols="6"
        lable="巡检记录总数"
        style="cursor: pointer"
        @click.native="
          $refs.inspectionTableDialog.openDialog({
            query: {
              searchAnalysisItemCodeList: 'remote_insp',
            },
            fixedParam: {
              violationFlag: undefined,
            },
          })
        "
      >
        <span style="color: #ffcc1b">{{
            formatString(remoteInfo.totalCount)
          }}</span>
      </QueryLayout>
      <QueryLayout
        :cols="6"
        lable="违规数"
        style="cursor: pointer"
        @click.native="
          $refs.inspectionTableDialog.openDialog({
            query: {
              searchAnalysisItemCodeList: 'remote_insp',
            },
            fixedParam: {
              violationFlag: '02',
            },
          })
        "
      >
        <span style="color: #ffcc1b">{{ formatString(remoteInfo.count) }}</span>
      </QueryLayout>
      <QueryLayout
        :cols="9"
        lable="机构名称"
        lable-style="width: 80px;text-center:right;"
      >
        <SelectTree
          v-model="queryParam.organizationUUID"
          :options="organizationTree"
          :replace-fields="{
            itemText: 'name',
            itemKey: 'uuid',
            itemChildren: 'children',
          }"
          placeholder="请选择机构名称"
        ></SelectTree>
      </QueryLayout>
      <QueryLayout
        :cols="9"
        lable="位置"
        lable-style="width: 80px;text-center:right;"
      >
        <Select
          v-model="queryParam.areaUUID"
          :options="commonAreaList"
          :replace-fields="{
            lable: 'text',
            value: 'value',
          }"
          placeholder="请选择位置"
        ></Select>
      </QueryLayout>
      <v-col :cols="12" v-if="cameraList.length">
<!--                <v-row class="scrollbar-y" style="height: 144px; cursor: pointer;margin-top: 20px">-->
<!--                  <QueryLayout-->
<!--                    v-for="e of cameraList"-->
<!--                    :key="e.uuid"-->
<!--                    :cols="6"-->
<!--                    :lable="e.cameraName"-->
<!--                    @click.native="$refs.cameraMonitoringDialog.openDialog({ row: e })"-->
<!--                  >-->
<!--                    <img src="@/assets/screen/bj/icon_video.png" alt="icon_video" />-->
<!--                  </QueryLayout>-->
<!--                </v-row>-->
        <div id="swiper-box" style="height: 60px; cursor: pointer;margin-top: 20px">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="e of cameraList" :key="e.uuid">
              <label for="">{{e.cameraName}}</label>
              <img @click="$refs.cameraMonitoringDialog.openDialog({ row: e })" src="@/assets/screen/bj/icon_video.png" alt="icon_video"/>

            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <CameraMonitoringDialog
      ref="cameraMonitoringDialog"
    ></CameraMonitoringDialog>
    <InspectionTableDialog ref="inspectionTableDialog"></InspectionTableDialog>
  </LayoutBox>
</template>

<script>
import {mapState} from 'vuex';
import {formatString} from '@/views/screen/config';
import Swiper from 'swiper/swiper-bundle.js'


export default {
  //组件注册
  components: {
    LayoutBox: () => import('@/views/screen/components/LayoutBox.vue'),
    QueryLayout: () => import('@/views/screen/components/QueryLayout.vue'),
    SelectTree: () => import('@/views/screen/components/SelectTree.vue'),
    Select: () => import('@/views/screen/components/Select.vue'),
    CameraMonitoringDialog: () => import('./CameraMonitoringDialog.vue'),
    InspectionTableDialog: () => import('./InspectionTableDialog.vue'),
  },
  //组件传值
  props: {
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      queryParam: {},
      remoteInfo: {},
      cameraList: [],
    };
  },
  //计算属性
  computed: {
    ...mapState({
      globalQuery: (state) => state.screenStore.globalQuery,
      organizationTree: (state) => state.screenStore.organizationTree,
      commonAreaList: (state) =>
        state.screenStore.analysisitem['commonAreaList']
          ? state.screenStore.analysisitem['commonAreaList']
          : [],
    }),
  },
  //属性监听
  watch: {
    queryParam: {
      deep: true,
      handler: 'getCamera',
    },
    refreshKey: {
      handler: 'getRemote',
    },
    globalQuery: {
      deep: true,
      immediate: true,
      handler: 'getRemote',
    },
  },
  //DOM访问
  mounted() {
    this.$bus.$on('global-refresh', this.getRemote);
    const [first] = this.organizationTree;
    if (first)
      this.queryParam = {
        organizationUUID: [first.uuid],
      };

  },
  //保存方法
  methods: {
    formatString,
    async getRemote() {
      const data = await this.$http.get(
        `/${this.$businessISG}/monitor/serve/remote-stat`,
        this.globalQuery
      );
      if (data.code == 200 && data.data) this.remoteInfo = data.data[0];

    },
    async getCamera() {
      const query = {...this.queryParam};
      if (Array.isArray(this.queryParam.organizationUUID))
        query.organizationUUID = this.queryParam.organizationUUID.toString();
      const data = await this.$http.get(
        `/${this.$businessISG}/monitor/serve/area-and-camera-list`,
        query
      );
      if (data.data) this.cameraList = data.data;
      this.$nextTick(() => {
        new Swiper('#swiper-box', {
          autoplay: false,
          spaceBetween:15,
          slidesPerView: 2,
          grid: {
            fill: 'row',
            rows: 2,
          }
        })

      })
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide{

  label{
    display: inline-block;
    width: calc(100% - 30px);
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  img{
    width: 25px;
    height: 25px;

  }
}
</style>
