var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutBox',{attrs:{"title":"远程巡检"}},[_c('v-row',{staticStyle:{"margin-top":"8px"}},[_c('QueryLayout',{staticStyle:{"cursor":"pointer"},attrs:{"cols":6,"lable":"巡检记录总数"},nativeOn:{"click":function($event){return _vm.$refs.inspectionTableDialog.openDialog({
            query: {
              searchAnalysisItemCodeList: 'remote_insp',
            },
            fixedParam: {
              violationFlag: undefined,
            },
          })}}},[_c('span',{staticStyle:{"color":"#ffcc1b"}},[_vm._v(_vm._s(_vm.formatString(_vm.remoteInfo.totalCount)))])]),_c('QueryLayout',{staticStyle:{"cursor":"pointer"},attrs:{"cols":6,"lable":"违规数"},nativeOn:{"click":function($event){return _vm.$refs.inspectionTableDialog.openDialog({
            query: {
              searchAnalysisItemCodeList: 'remote_insp',
            },
            fixedParam: {
              violationFlag: '02',
            },
          })}}},[_c('span',{staticStyle:{"color":"#ffcc1b"}},[_vm._v(_vm._s(_vm.formatString(_vm.remoteInfo.count)))])]),_c('QueryLayout',{attrs:{"cols":9,"lable":"机构名称","lable-style":"width: 80px;text-center:right;"}},[_c('SelectTree',{attrs:{"options":_vm.organizationTree,"replace-fields":{
            itemText: 'name',
            itemKey: 'uuid',
            itemChildren: 'children',
          },"placeholder":"请选择机构名称"},model:{value:(_vm.queryParam.organizationUUID),callback:function ($$v) {_vm.$set(_vm.queryParam, "organizationUUID", $$v)},expression:"queryParam.organizationUUID"}})],1),_c('QueryLayout',{attrs:{"cols":9,"lable":"位置","lable-style":"width: 80px;text-center:right;"}},[_c('Select',{attrs:{"options":_vm.commonAreaList,"replace-fields":{
            lable: 'text',
            value: 'value',
          },"placeholder":"请选择位置"},model:{value:(_vm.queryParam.areaUUID),callback:function ($$v) {_vm.$set(_vm.queryParam, "areaUUID", $$v)},expression:"queryParam.areaUUID"}})],1),(_vm.cameraList.length)?_c('v-col',{attrs:{"cols":12}},[_c('div',{staticStyle:{"height":"60px","cursor":"pointer","margin-top":"20px"},attrs:{"id":"swiper-box"}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.cameraList),function(e){return _c('div',{key:e.uuid,staticClass:"swiper-slide"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(e.cameraName))]),_c('img',{attrs:{"src":require("@/assets/screen/bj/icon_video.png"),"alt":"icon_video"},on:{"click":function($event){return _vm.$refs.cameraMonitoringDialog.openDialog({ row: e })}}})])}),0)])]):_vm._e()],1),_c('CameraMonitoringDialog',{ref:"cameraMonitoringDialog"}),_c('InspectionTableDialog',{ref:"inspectionTableDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }